require('./bootstrap');
var bootstrap = require('bootstrap');
require('admin-lte');
require('@fortawesome/fontawesome-free/js/fontawesome.js');
require('@fortawesome/fontawesome-free/js/regular.js');
require('@fortawesome/fontawesome-free/js/solid.js');
require('@fortawesome/fontawesome-free/js/brands.js');
window.$ = require( "jquery" );
window.Croppie = require("croppie");
window.QRCode = require('qrcode');
require('./excel.js');
require("friendly-challenge/widget");

$(document).ready(function () {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
});



window.linkSelectDropdown = function(selection, shortcode)
{
    $('#linkDropdown').html($(selection).html());
    $('#linkType').val(shortcode);
    return false;
};

window.linkSelectInsert = function()
{
    var shortcode = $('#linkType').val();
    var url = $('#linkUrl').val();
    var icon_element = $('#linkDropdown').children('svg');
    var icon = icon_element.wrap('<p/>').parent().html();
    icon_element.unwrap();
    $('#linkUrl').val('');

    if (url.length <= 0) {
      return false;
    }

    var html = '<li class="list-group-item">';
    html += icon + ' ' + url + ' ';
    html += '<a class="float-right" href="javascript:;" onclick="linkSelectDelete(this)"><i class="far fa-trash-alt text-danger"></i></a>';
    html += '<input type="hidden" name="social_media_type[]" value="' + shortcode + '">';
    html += '<input type="hidden" name="social_media_url[]" value="' + url + '">';
    html += '</li>';

    $('#linkContainer').append(html);
    return false;
};

window.linkSelectDelete = function(element)
{
    $(element).parent().remove();
};


window.accountsAddAccount = function(element)
{
    $(element).addClass('disabled').prop('disabled', 'disabled');

    axios.get(window.base_url + '/addAccount')
        .then(function(data) {
            $(element).removeClass('disabled').prop('disabled', false);
            if (data.status != 200 || data.data.message != 'success') {
                alert("Oops, da ist etwas schiefgelaufen.");
                return;
            }

            var username = data.data.data;
            var html = "<tr>";
            html += '<td><a class="btn btn-sm btn-secondary disabled" href="#"><i class="fas fa-eye"></i></a></td>';
            html += '<td style="font-size: 0.8em;vertical-align: middle;"><b style="font-family: monospace">' + username + '</b> - <b>Unterprofil</b></td>';
            html += '<td></td>';
            html += '<td></td>';
            if ($('table tr').first().children().length === 6) {
                html += '<td></td>';
            }
            html += '<td><a href="' + window.base_url + '/id/' + username + '"><i class="fas fa-search" title="Profil betrachten"></i></a> - ';
            html += '<a href="' + window.base_url + '/edit/' + username + '"><i class="fas fa-user-edit" title="Bearbeiten"></i></a> - ';
            html += '<a href="' + window.base_url + '/credentials/' + username + '"><span class="fa-layers "><i class="fas fa-key"></i><i class="fas fa-pen fa-inverse" data-fa-transform="down-3 right-6 shrink-3"></i><i class="fas fa-pen" data-fa-transform="down-3 right-6 shrink-7"></i></span></a>';
            html += "</tr>";

            $('#accountContainer').append(html);

            if (data.data.credit <= 0) {
                $(element).remove();
            }
            $('#saCount').text(parseInt($('#saCount').text())+1);
            $('#accountDummy').remove();
        })
        .catch(function(data) {
            $(element).removeClass('disabled').prop('disabled', false);
            alert("Oops, da ist etwas schiefgelaufen.");
        });
};

window.croppie = false;
window.avatarUpload = function(token)
{
    if ($('#avatarModalFile').val().length < 2) {
        avatarUploadError("Bitte wähle ein Bild aus!");
        return;
    }

    $('#avatarModalButton').addClass('btn-disabled').prop('disabled', 'disabled');

    window.croppie.result(
      {type: 'blob', size: {width: window.template_avatar_x, height: window.template_avatar_y}, circle: false})
      .then(function (file) {
        console.log(file);
        avatarSendFile(file, token);
    });
};

function avatarSendFile(file, token)
{
    var formData = new FormData();
    formData.append("image", file);
    var or = $("#avatar_override");
    if (or && or.is(':checked')) {
        formData.append('avatar_override', '1');
    }
    axios.post(window.base_url + '/changeAvatar/' + token, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then(function (data) {
        var timestamp = new Date().getTime();
        $('#avatarImg').prop('src', data.data.data + '?t=' + timestamp);
        $('#avatarModalCancel').click();
    })
    .catch(function (data) {
        console.log(data);
        avatarUploadError("Oops, etwas ist schiefgelaufen!");
    })
    .then(function (data) {
        $('#avatarModalButton').removeClass('btn-disabled').prop('disabled', false);
    });
};
function avatarUploadError(error)
{
    $('#avatarModalError').removeClass('alert-info').addClass('alert-danger');
    $('#avatarModalError').text(error);
};
window.avatarSelect = function(element)
{
    var url = window.URL.createObjectURL(element.files[0]);
    var el = document.getElementById('avatarCroppie');
    if (window.croppie) {
        window.croppie.destroy();
    }
    window.croppie = new Croppie(el, {
        viewport: {
            width: 280,
            height: 280*window.template_avatar_y/window.template_avatar_x,
        },
        boundary: {
            width: 400,
            height: 400*window.template_avatar_y/window.template_avatar_x,
        }
    });
    window.croppie.bind({url: url});
};


window.onWebButton = function(e) {
    $('#web2Container').show();
    $('#web1Button').hide();
    $('#web1Input').removeClass("border-right-flat");
    return false;
}

window.onAddDesignFormField = function(e) {
    $('#designUploadWrapper').append('<input type="file" name="upload[]" id="designUpload" class="form-control mt-1" multiple />');
}
