const { default: axios } = require("axios");

var excelArray = [];
window.excelArrayIterator = 0;
var excelEmptySubusers = 0;
var excelSpinnerHtml = `<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>`;

$(function() {
  $("#excelForm").on("submit", function(e) {
    e.preventDefault();
    const fileList = $("#excelFile")[0].files;
    if (fileList.length > 0) {
      processExcelFile(fileList[0]);
    }
  });
  $("#excelMaster").on("change", function(e) {
    getEmptySubuser();
  });
});

function processExcelFile(file) {
  const reader = new FileReader();
  reader.addEventListener('load', (event) => {
    var result = CSVToArray(event.target.result, ";");
    result = trimEmptyArrays(result);
    if (result.length <= 0) {
      alert("Die CSV hat kein korrektes Format!");
      return;
    }
    if (result[0].length != 17 && result[0].length != 16) {
      alert("Die CSV hat kein korrektes Format! Unerwartete Anzahl an Spalten!");
      return;
    }
    excelArray = result;
    renderExcelPreview();
  });

  reader.addEventListener('progress', (event) => {
    if (event.loaded && event.total) {
      const percent = (event.loaded / event.total) * 100;
      console.log(`Progress: ${Math.round(percent)}`);
    }
  });
  reader.readAsText(file);
}

function renderExcelPreview() {
  var html = "";
  html += `Eingelesene Profile: ${excelArray.length} - `;
  html += `Leere Unterprofile: <span id="excelSubUserCount">${excelSpinnerHtml}</span>`;
  html += `<button class="btn btn-primary m-3" onclick="startImport()" id="excelProfileImportButton"><span id="excelProfileImportCount">${excelSpinnerHtml}</span> Profile importieren</button>`;
  $("#excelStatusContainer").html(html);

  html = "";
  html += `<table class="table table-striped table-bordered" style="font-size: 0.6em">`;
  html += `<tr>`;
  html += `<th>Vorname</th><th>Name</th><th>Position</th><th>Firma</th><th>Adresszeile 1</th><th>Adresszeile 2</th><th>PLZ</th><th>Stadt</th><th>Land</th><th>Tel</th><th>Fax</th><th>Mobil</th><th>Email</th><th>Web</th><th>Sonstiges</th><th>Social Media</th>`;
  html += `</tr>`;
  for (let i in excelArray) {
    html += `<tr>`;
    html += `<td>${excelArray[i][0]}</td><td>${excelArray[i][1]}</td><td>${excelArray[i][2]}</td><td>${excelArray[i][3]}</td><td>${excelArray[i][4]}</td><td>${excelArray[i][5]}</td><td>${excelArray[i][6]}</td><td>${excelArray[i][7]}</td><td>${excelArray[i][8]}</td><td>${excelArray[i][9]}</td><td>${excelArray[i][10]}</td><td>${excelArray[i][11]}</td><td>${excelArray[i][12]}</td><td>${excelArray[i][13]}</td><td>${excelArray[i][14]}</td><td>${excelArray[i][15]}</td>`;
    html += `</tr>`;
  }
  $("#excelPreviewContainer").html(html);
  $("#excelMaster").removeClass("d-none");
  $("#selectSearch").removeClass("d-none");
  getEmptySubuser();
}

function trimEmptyArrays(array) {
  // first column contains ids
  array.splice(0, 1);

  for(let i in array) {
    if(array[i].length <= 1) {
      array.splice(i, 1);
    }
  }
  return array;
}

window.startImport = function() {
  excelArrayIterator = 0;
  $("#excelProfileImportButton").addClass("disabled");
  $("#excelProfileImportButton").attr("disabled", "disabled");
  $("#excelMaster").attr("disabled", "disabled");
  $("#selectSearch").attr("disabled", "disabled");
  $("#excelFile").attr("disabled", "disabled");
  $("#excelFileButton").addClass("disabled");
  $("#excelFileButton").attr("disabled", "disabled");
  let numProfileImporting = Math.min(excelEmptySubusers, excelArray.length);
  var html = "";
  html += `<div class="progress mb-3" style="max-width: 600px; margin:auto;">`;
  html += `<div id="excelProgressBar" class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="${numProfileImporting}"></div>`;
  html += `</div>`;
  $("#excelProgressContainer").html(html);
  importAsync();
}

window.importAsync = function() {
  console.log("importing", excelArrayIterator);
  let numProfileImporting = Math.min(excelEmptySubusers, excelArray.length);
  if (excelArrayIterator >= numProfileImporting) return reportFinalExcelStatus(true);
  let token = $("#excelMaster").val();
  var payload = {
    "surname": excelArray[excelArrayIterator][0],
    "name": excelArray[excelArrayIterator][1],
    "position": excelArray[excelArrayIterator][2],
    "company": excelArray[excelArrayIterator][3],
    "street1": excelArray[excelArrayIterator][4],
    "street2": excelArray[excelArrayIterator][5],
    "post_code": excelArray[excelArrayIterator][6],
    "city": excelArray[excelArrayIterator][7],
    "country": excelArray[excelArrayIterator][8],
    "phone": excelArray[excelArrayIterator][9],
    "fax": excelArray[excelArrayIterator][10],
    "mobile": excelArray[excelArrayIterator][11],
    "email": excelArray[excelArrayIterator][12],
    "web": excelArray[excelArrayIterator][13],
    "custom": excelArray[excelArrayIterator][14],
    "social_media": excelArray[excelArrayIterator][15],
    "social_media_type": excelArray[excelArrayIterator][16] ?? getSocialMediaType(excelArray[excelArrayIterator][15]),
  };
  console.log(payload);

  axios.post(window.base_url + '/admin/insertBatchSub/' + token, payload)
  .then(function(response) {
    excelArrayIterator++;
    updateImportProgress();
    importAsync();
  })
  .catch(function(error) {
    console.log(error);
    reportFinalExcelStatus(false);
  });
}

function updateImportProgress() {
  let numProfileImporting = Math.min(excelEmptySubusers, excelArray.length);
  let progress = (excelArrayIterator / numProfileImporting) * 100;
  $("#excelProgressBar").attr("aria-valuenow", excelArrayIterator);
  $("#excelProgressBar").css("width", Math.round(progress) + "%");
}

function getEmptySubuser() {
  $("#excelSubUserCount").html(excelSpinnerHtml);
  let token = $("#excelMaster").val();
  if (token == "" || token == null) return;
  axios.get(window.base_url + '/admin/countEmptySub/' + token)
  .then(function (data) {
    let subcount = data.data.count;
    excelEmptySubusers = subcount;
    let numProfileImporting = Math.min(excelEmptySubusers, excelArray.length);
    $("#excelSubUserCount").html(subcount);
    $("#excelProfileImportCount").html(numProfileImporting);
  })
  .catch(function (error) {
    console.log(error);
  });
}

function reportFinalExcelStatus(success) {
  var html = "";
  html += "<p>Importstatus: ";
  if (success) {
    html += `<span class="text-success"><b>Erfolg</b></span>`;
  } else {
    html += `<span class="text-danger"><b>Fehlerhaft</b></span>`;
  }
  html += "</p>";

  if (!success) {
    html += "<p>Oops, etwas ist schiefgelaufen beim letzten Import. Kontrolliere die bereits importierten Profildaten, dann kannst du den Import entweder fortsetzen oder abbrechen.</p>";
    html += `<button class="btn btn-warning mb-3 me-3" onclick="$('#excelFinalStatusContainer').html('');importAsync();">Erneut versuchen</button>`;
    html += `<button class="btn btn-warning mb-3 me-3" onclick="$('#excelFinalStatusContainer').html('');excelArrayIterator++;importAsync();">Profil überspringen</button>`;
  }
  html += `<button class="btn btn-primary mb-3" onclick="resetExcel();">Fertig</button>`;

  $("#excelFinalStatusContainer").html(html);
}

window.resetExcel = function() {
  $("#excelStatusContainer").html("");
  $("#excelProgressContainer").html("");
  $("#excelPreviewContainer").html("");
  $("#excelFinalStatusContainer").html("");

  $("#excelFile").attr("disabled", false);
  $("#excelFileButton").removeClass("disabled");
  $("#excelFileButton").attr("disabled", false);
  $("#excelMaster").attr("disabled", false);
  $("#excelMaster").addClass("d-none");
  $("#selectSearch").attr("disabled", false);
  $("#selectSearch").addClass("d-none");

  excelArray = [];
  excelArrayIterator = 0;
  excelEmptySubusers = 0;
}

function getSocialMediaType(link) {
  if (link.includes("facebook.com")) {
    return "fb";
  }
  if (link.includes("instagram.com")) {
    return "ig";
  }
  if (link.includes("xing.com")) {
    return "xi";
  }
  if (link.includes("linkedin.com")) {
    return "li";
  }
  if (link.includes("twitter.com")) {
    return "tw";
  }
  if (link.includes("youtube.com")) {
    return "yt";
  }
  if (link.includes("youtu.be")) {
    return "yt";
  }
  if (link.includes("tiktok.com")) {
    return "tt";
  }
  if (link.includes("snapchat.com")) {
    return "sc";
  }
  if (link.includes("pinterest.com")) {
    return "pt";
  }
  if (link.includes("spotify.com")) {
    return "sf";
  }
  if (link.includes("paypal.com")) {
    return "pp";
  }
  if (link.includes("skype.com")) {
    return "sk";
  }
  if (link.includes("wa.me/")) {
    return "wa";
  }
  if (link.includes("teams.microsoft.com")) {
    return "mt";
  }
  return "";
}


// ref: http://stackoverflow.com/a/1293163/2343
// This will parse a delimited string into an array of
// arrays. The default delimiter is the comma, but this
// can be overriden in the second argument.
function CSVToArray( strData, strDelimiter ){
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = (strDelimiter || ",");
  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
      (
          // Delimiters.
          "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
          // Quoted fields.
          "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
          // Standard fields.
          "([^\"\\" + strDelimiter + "\\r\\n]*))"
      ),
      "gi"
      );
  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData = [[]];
  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;
  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches = objPattern.exec( strData )){
      // Get the delimiter that was found
      var strMatchedDelimiter = arrMatches[ 1 ];
      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (
          strMatchedDelimiter.length &&
          strMatchedDelimiter !== strDelimiter
          ){
          // Since we have reached a new row of data,
          // add an empty row to our data array.
          arrData.push( [] );
      }
      var strMatchedValue;
      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[ 2 ]){
          // We found a quoted value. When we capture
          // this value, unescape any double quotes.
          strMatchedValue = arrMatches[ 2 ].replace(
              new RegExp( "\"\"", "g" ),
              "\""
              );
      } else {
          // We found a non-quoted value.
          strMatchedValue = arrMatches[ 3 ];
      }
      // Now that we have our value string, let's add
      // it to the data array.
      arrData[ arrData.length - 1 ].push( strMatchedValue );
  }

  // Return the parsed data.
  return( arrData );
}
